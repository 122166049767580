import { ReactNode } from 'react'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { GOOGLE_RECAPTCHA_SITE_KEY } from '~/constants'

interface ReCaptchaProviderProps {
  children: ReactNode
}

export const ReCaptchaProvider = ({ children }: ReCaptchaProviderProps) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}>
      {children}
    </GoogleReCaptchaProvider>
  )
}
