import React, { FC } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { BoxProps } from '@material-ui/core/Box'

type Props = {
  size?: number
}

const Loader: FC<BoxProps & Props> = ({ size = 100, ...props }) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      m="auto"
      {...props}
    >
      <CircularProgress size={size} />
    </Box>
  )
}

export default Loader
