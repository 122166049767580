import React, { FC } from 'react'

import { Box, Paper } from '@material-ui/core'
import { BoxProps } from '@material-ui/core/Box'

const SimpleCard: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      component={Paper}
      p={2.5}
      {...props}
      boxShadow="0px 7px 10px rgba(0, 0, 0, 0.08) !important"
    >
      {children}
    </Box>
  )
}

export default SimpleCard
