import { useCallback, useMemo } from 'react'

import { createRangeStep } from '~/utils/functions'

import { priceIntervals } from '~/data/intervals'

type SliderValues = {
  minValue: number
  maxValue: number
  sliderSteps?: {
    value: number
    step: number
  }[]
}

const useSlider = ({
  minValue,
  maxValue,
  sliderSteps = priceIntervals
}: SliderValues) => {
  const marks = useMemo(
    () =>
      createRangeStep(
        +minValue,
        +maxValue,
        sliderSteps.filter((i) => i.value < +maxValue),
        () => ''
      ).map((mark, index) => ({
        ...mark,
        value: index * 25,
        scaledValue: mark.value
      })),
    [minValue, maxValue, sliderSteps]
  )

  const min = 0
  const max = marks[marks.length - 1]?.value || 0

  const scale = useCallback(
    (value) => {
      if (!marks.length) return 0

      const previousMarkIndex = Math.floor(+value / 25)
      const previousMark = marks[previousMarkIndex]
      const remainder = +value % 25

      if (remainder === 0) {
        return previousMark?.scaledValue
      }

      const nextMark = marks[previousMarkIndex + 1]
      const increment = (nextMark?.scaledValue - previousMark?.scaledValue) / 25

      return remainder * increment + previousMark?.scaledValue
    },
    [marks]
  )

  const parse = useCallback(
    (value) => {
      return marks.find((v) => v?.scaledValue === +value)?.value
    },
    [marks]
  )

  const parseRange = useCallback(
    (range) => Array.isArray(range) && [parse(range[0]), parse(range[1])],
    [parse]
  )

  const scaleRange = useCallback(
    (range) => Array.isArray(range) && [scale(range[0]), scale(range[1])],
    [scale]
  )

  return { parse, parseRange, scale, scaleRange, min, max, marks }
}

export default useSlider
