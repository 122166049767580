import React, { FC } from 'react'

import { AdSlot } from 'react-dfp'

import { NoSsr } from '@material-ui/core'

type AdsSlotProps = {
  slotId: string
  sizes?: number[][]
  sizeMapping?: { viewport: number[]; sizes: (number[] | string)[] }[]
  targetingArguments: { pos: string; native?: string }
  renderOutOfThePage?: boolean
  disableSizeMaping?: boolean
  shouldRefresh?: () => boolean
  onSlotRender?: (eventData: any) => any
}

const AdsSlot: FC<AdsSlotProps> = ({
  slotId,
  sizes,
  sizeMapping,
  targetingArguments,
  renderOutOfThePage,
  disableSizeMaping,
  onSlotRender,
  shouldRefresh
}) => {
  const defaultSize = [
    [970, 90],
    [728, 90],
    [320, 100],
    [320, 50],
    [300, 250],
    [300, 50],
    [210, 60]
  ]

  const defaultSizeMaping = [
    {
      viewport: [2250, 0],
      sizes: [[970, 90], [728, 90], 'fluid']
    },
    {
      viewport: [1279, 0],
      sizes: [[728, 90], 'fluid']
    },
    {
      viewport: [894, 0],
      sizes: [[728, 90], 'fluid']
    },
    {
      viewport: [0, 0],
      sizes: [
        [300, 250],
        [320, 100],
        [320, 50],
        [300, 250],
        [300, 50]
      ]
    }
  ]

  return (
    <NoSsr>
      <AdSlot
        renderOutOfThePage={renderOutOfThePage}
        slotId={slotId}
        shouldRefresh={shouldRefresh}
        sizes={sizes || defaultSize}
        sizeMapping={
          sizeMapping || (disableSizeMaping ? [] : defaultSizeMaping)
        }
        targetingArguments={targetingArguments}
        onSlotRender={onSlotRender}
      />
    </NoSsr>
  )
}

export default AdsSlot
