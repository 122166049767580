import React, { FC, useEffect, useRef, useMemo, useCallback } from 'react'

import { DFPSlotsProvider as Provider, DFPManager } from 'react-dfp'

import useIdleCallback from '~/hooks/useIdleCallback'
import { safelyStringifyJSON } from '~/utils/functions'

import { IS_BROWSER } from '~/constants'

type AdProviderProps = {
  pageType: string
  targetingArguments?: any
  pageKey?: string
  adUnit: string
  disabled?: boolean
}

const DFPSlotsProvider: FC<AdProviderProps> = ({
  pageType,
  pageKey,
  adUnit,
  targetingArguments = {},
  children,
  disabled
}) => {
  const loaded = useRef(false)
  const stringArgs = IS_BROWSER
    ? safelyStringifyJSON({
        ...targetingArguments,
        key: pageKey
      })
    : null

  const filteredTargetingArguments = useMemo(
    () =>
      Object.keys(targetingArguments).reduce((acc, key) => {
        if (targetingArguments[key] !== undefined) {
          acc[key] = String(targetingArguments[key])
        }
        return acc
      }, {}),
    [targetingArguments]
  )

  useEffect(() => {
    DFPManager.setTargetingArguments({
      pagetype: pageType,
      ...filteredTargetingArguments,
      key: pageKey
    })

    if (loaded.current) {
      DFPManager.reload()
    }
  }, [stringArgs])

  useIdleCallback(
    useCallback(() => {
      if (disabled) return
      const initGTMOnEvent = (event) => {
        DFPManager.load()
        loaded.current = true
        event.currentTarget.removeEventListener(event.type, initGTMOnEvent)
      }
      const loadedHandler = () => {
        document.addEventListener('scroll', initGTMOnEvent, { passive: true })
        document.addEventListener('mousemove', initGTMOnEvent, {
          passive: true
        })
        document.addEventListener('touchstart', initGTMOnEvent, {
          passive: true
        })
      }

      if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', loadedHandler)
      } else {
        loadedHandler()
      }

      return () => {
        document.removeEventListener('DOMContentLoaded', loadedHandler)
      }
    }, [disabled])
  )

  return (
    <Provider
      dfpNetworkId="21835225003"
      adUnit={`${process.env.ADS_KEY}/${adUnit}`}
      lazyLoad={{
        fetchMarginPercent: 50,
        renderMarginPercent: 12.5,
        mobileScaling: 2.0
      }}
      singleRequest={false}
      autoLoad={false}
      collapseEmptyDivs
    >
      {children}
    </Provider>
  )
}

export default DFPSlotsProvider
