import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useSearchBarStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        [theme.breakpoints.down('md')]: {
          maxWidth: 500,
          width: '100%',
          margin: '0 auto',
          '& .MuiFormControl-root': {
            maxWidth: '100%',
            width: '100%'
          }
        },
        '& .MuiFormLabel-root > *': {
          fontFamily: 'Prompt, sans-serif'
        }
      },
      search: {
        display: 'flex',
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        background: theme.palette.common.white,
        '& > *:not(hr)': {
          flex: 1
        },
        '& hr': {
          margin: '16px 0'
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          background: 'transparent',
          '& hr': {
            display: 'none'
          }
        }
      },
      searchBtn: {
        borderRadius: 0,
        fontSize: 20,
        [theme.breakpoints.down('md')]: {
          display: 'none'
        }
      },
      searchBtnMobile: {
        marginTop: theme.spacing(2),
        display: 'none',
        fontSize: 20,
        [theme.breakpoints.down('md')]: {
          display: 'block'
        }
      },
      filled: {
        '& .MuiFilledInput-root': {
          background: `${theme.palette.common.white} !important`
        },
        '& .MuiInputBase-root': {
          fontFamily: 'Prompt, sans-serif',
          paddingBottom: '12px',
          paddingRight: '40px !important',
          fontSize: '18px',
          borderRadius: 0,
          [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1),
            borderRadius: 4,
            maxHeight: '50px'
          }
        },
        '& .MuiInputLabel-filled': {
          fontFamily: 'Prompt, sans-serif'
        },
        '& .MuiInputLabel-filled[data-shrink="false"]': {
          transform: 'translate(12px, 29px) scale(1)',
          fontSize: '18px',
          [theme.breakpoints.down('md')]: {
            transform: 'translate(12px, 16px) scale(1)'
          }
        },
        '& .MuiInputLabel-filled[data-shrink="true"]': {
          color: theme.palette.primary.main,
          [theme.breakpoints.down('md')]: {
            transform: 'translate(12px, 6px) scale(0.75)',
            fontSize: '12px'
          }
        },
        '& .MuiInputLabel-filled:not([data-shrink="true"]):not(.Mui-disabled)': {
          color: theme.palette.text.primary
        },
        '& .MuiAutocomplete-endAdornment': {
          marginTop: 4
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
          [theme.breakpoints.down('md')]: {
            paddingTop: 2
          }
        }
      }
    }),
  { name: 'SearchBar' }
)

export const useRecentlyAddedSectionStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        [theme.breakpoints.up('md')]: {
          marginBottom: '62px'
        },
        [theme.breakpoints.up('lg')]: {
          marginBottom: 0
        }
      }
    }),
  { name: 'RecentlyAddedSection' }
)

export const useHomepageHeroStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      hero: {
        position: 'relative',
        minWidth: '320px',
        zIndex: 1,
        padding: `${theme.spacing(8)}px 0`,
        '& img.home-hero-image': {
          zIndex: -1
        }
      },
      createAdBtn: {
        color: theme.palette.primary.main,
        '&:hover': {
          background: theme.palette.primary.dark,
          color: theme.palette.common.white
        }
      }
    }),
  { name: 'HomepageHero' }
)
