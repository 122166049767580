import React from 'react'

import { WrappedFieldProps } from 'redux-form'

import { RadioGroup } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import FormControl from '~/components/shared/FormControl'

import RadioField from './RadioField'

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        flexDirection: 'row'
      },

      label: {
        marginBottom: 10
      }
    }),
  { name: 'RadioGroupField' }
)

type Props = {
  label: string
  disabled?: boolean
  displayError?: string
  items?: {
    label: string
    value: string | number
  }[]
}

const RadioGroupField: React.FC<WrappedFieldProps & Props> = ({
  input: { value, ...input },
  meta: { touched, error },
  label,
  displayError,
  items,
  disabled,
  ...rest
}) => {
  const cn = useStyles()

  const errorMessage = (touched && error) || displayError

  return (
    <FormControl
      label={label}
      errorMessage={errorMessage}
      error={!!errorMessage}
    >
      <RadioGroup
        value={String(value)}
        className={cn.root}
        {...input}
        {...rest}
      >
        {items?.length ? (
          items.map(({ label, value: itemValue }, idx) => (
            <RadioField
              key={idx}
              label={label}
              value={itemValue}
              disabled={disabled}
            />
          ))
        ) : (
          <>
            <RadioField label="Yes" value="true" disabled={disabled} />
            <RadioField label="No" value="false" disabled={disabled} />
          </>
        )}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioGroupField
