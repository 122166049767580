import { useEffect, useMemo, useRef } from 'react'

import { useQuery } from 'react-query'

import Deserializer from '~/services/Deserializer'
import { formatOption, isOfType } from '~/utils/functions'

export type OptionsProps = {
  options?: any[]
  loadOptions?: () => Promise<any>
  formatOptions?: (resp: any) => Promise<any> | any
  optionLabel?: string
  optionValue?: string
  value?: any
  key: any
  caseSensitive?: boolean
  refetchDeps?: any[]
  deserialize?: boolean
  enabled?: boolean
}

const useOptions = ({
  options: customOptions = [],
  loadOptions,
  formatOptions,
  optionLabel = 'name',
  optionValue = 'id',
  value,
  key = null,
  caseSensitive,
  refetchDeps = [],
  deserialize,
  enabled
}: OptionsProps) => {
  const isMount = useRef(false)
  const { data: options, isLoading, refetch } = useQuery(
    key,
    () =>
      loadOptions().then(async (resp) => {
        let newOptions: any[] = resp

        if (deserialize) {
          newOptions = await Deserializer.deserialize(resp)
        }

        return newOptions
      }),
    {
      enabled: !!loadOptions && enabled,
      select: (data) => {
        let formattedOps = data.map((option) =>
          formatOption({
            option,
            optionLabel,
            optionValue
          })
        )

        if (typeof formatOptions === 'function') {
          formattedOps = formatOptions(formattedOps)
        }

        return formattedOps
      },
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (isMount.current) {
      refetch()
    }

    isMount.current = true
  }, refetchDeps)

  const currentOptions = useMemo(() => {
    if (Array.isArray(customOptions) && !options?.length) {
      return customOptions.map((option) =>
        formatOption({
          option,
          optionLabel,
          optionValue
        })
      )
    }

    return options
  }, [customOptions, optionLabel, optionValue, options?.length])

  const currentValue = useMemo(() => {
    if (!value) return null

    const primitive = isOfType.string(value) || isOfType.number(value)
    const isOptions = !!currentOptions.length

    const getValueFromOptions = (currValue) => {
      const formatValue = (v) => {
        if (caseSensitive) {
          return String(v)
        }

        return String(v).toLowerCase()
      }

      return currentOptions.find(
        (option) =>
          formatValue(option.value) ===
          formatValue(currValue?.[optionValue] || currValue)
      )
    }

    if (primitive && isOptions) {
      return getValueFromOptions(value)
    }
    if (isOfType.object(value) && value?.[optionValue] && isOptions) {
      return getValueFromOptions(value)
    }
    if (isOfType.array(value) && isOptions) {
      return value
        .map((currValue) => getValueFromOptions(currValue))
        .filter(Boolean)
    }

    return value
  }, [value, optionValue, currentOptions])

  return {
    options: currentOptions,
    currentValue,
    loading: isLoading,
    refetch
  }
}

export default useOptions
