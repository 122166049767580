import { SingletonRouter, withRouter } from 'next/router'
import React, { useEffect } from 'react'

import { compose } from '@reduxjs/toolkit'
import { diff } from 'deep-object-diff'
import { connect, useSelector } from 'react-redux'
import {
  Field,
  formValueSelector,
  getFormInitialValues,
  InjectedFormProps,
  reduxForm
} from 'redux-form'

import { Box, Divider } from '@material-ui/core'

import SimpleCard from '~/components/cards/SimpleCard'
import AutocompleteField from '~/components/fields/AutocompleteField'
import RadioGroupField from '~/components/fields/RadioGroupField'
import SliderField from '~/components/fields/SliderField'
import TextField from '~/components/fields/TextField'
import ExtendedButton from '~/components/shared/ExtendedButton'
import FormControl from '~/components/shared/FormControl'

import useSlider from '~/hooks/useSlider'
import EmpriseGroup from '~/services/EmpriseGroup'
import IntlService from '~/services/IntlService'
import { AppState } from '~/state/store'
import { createSubmit } from '~/utils/createSubmit'
import Paths from '~/utils/paths'

import { conditionOptions } from '~/data/options'

import { CACHE_KEYS } from '~/constants'

import { useSearchBarStyles } from './homages.styles'

const InitialValues = {
  price: [0, 100],
  by_condition: 'New & Used'
}

type Props = {
  meta: { max_price: number; min_price: number }
}

const selector = formValueSelector('Search_Form')

const SearchBar = ({
  handleSubmit,
  initialize,
  meta
}: InjectedFormProps & Props) => {
  const classes = useSearchBarStyles()

  const by_type = useSelector((state: AppState) => selector(state, 'by_type'))
  const price = useSlider({
    minValue: +meta?.min_price,
    maxValue: +meta?.max_price
  })

  useEffect(() => {
    if (meta) {
      initialize({
        ...InitialValues,
        price: [+meta?.min_price, +meta?.max_price]
      })
    }
  }, [meta])

  return (
    <SimpleCard className={classes.root}>
      <form onSubmit={createSubmit({ handleSubmit })}>
        <Box borderRadius={4} mb={2} className={classes.search}>
          <Field
            className={classes.filled}
            id="by_type"
            name="by_type"
            label="Category"
            placeholder="Category"
            variant="filled"
            component={AutocompleteField}
            optionSettings={{
              key: CACHE_KEYS.types,
              loadOptions: EmpriseGroup.getMainCategories,
              optionValue: 'name'
            }}
            disabledPopupIcon
            disableLoader
            disablePortal={false}
            loadOptionsOnClick
          />
          <Divider orientation="vertical" flexItem />
          <Field
            className={classes.filled}
            id="category"
            name="category"
            label="Subcategory"
            placeholder="Subcategory"
            variant="filled"
            component={AutocompleteField}
            optionSettings={{
              key: [CACHE_KEYS.categories, by_type],
              loadOptions:
                !!by_type &&
                (() => EmpriseGroup.getCategories({ type_name: by_type })),
              deserialize: true,
              refetchDeps: [by_type],
              optionValue: 'name'
            }}
            disabledPopupIcon
            disabled={!by_type}
            disableLoader
            disablePortal={false}
            loadOptionsOnClick
          />
          <Divider orientation="vertical" flexItem />

          <Field
            className={classes.filled}
            id="make.name"
            name="make.name"
            label="Make"
            placeholder="Make"
            variant="filled"
            component={AutocompleteField}
            optionSettings={{
              key: CACHE_KEYS.makes,
              loadOptions: () =>
                EmpriseGroup.getBrands({ with_listings: true }),
              deserialize: true,
              optionValue: 'name'
            }}
            disabledPopupIcon
            optionLabel="name"
            disableLoader
            disablePortal={false}
            loadOptionsOnClick
          />
          <Divider orientation="vertical" flexItem />

          <Field
            className={classes.filled}
            id="location"
            name="location"
            label="Location"
            placeholder="Location"
            variant="filled"
            component={AutocompleteField}
            optionSettings={{
              key: CACHE_KEYS.locations,
              loadOptions: EmpriseGroup.getLocations,
              deserialize: true,
              optionValue: 'name'
            }}
            disabledPopupIcon
            disableLoader
            disablePortal={false}
            loadOptionsOnClick
          />
          <Divider orientation="vertical" flexItem />

          <Field
            className={classes.filled}
            id="keyword"
            name="keyword"
            label="Keyword"
            placeholder="Keyword"
            variant="filled"
            component={TextField}
            autoComplete="off"
            InputProps={{
              disableUnderline: true
            }}
          />
          <ExtendedButton
            type="submit"
            maxWidth={160}
            className={classes.searchBtn}
          >
            Search
          </ExtendedButton>
        </Box>
        <Box display={{ xs: 'block', lg: 'flex' }} justifyContent="center">
          <FormControl label="Price" maxWidth={320}>
            <Box width="100%" px="12px">
              <Field
                key={`${price.min}_${price.max}`}
                id="price"
                name="price"
                component={SliderField}
                valueLabelDisplay="auto"
                aria-labelledby="price-slider"
                min={price.min}
                max={price.max}
                scale={price.scale}
                step={null}
                defaultValue={[price.min, price.max]}
                price
                getAriaValueText={(value) => IntlService.compactFormat(value)}
                valueLabelFormat={(value) => {
                  if (+value === +meta?.min_price) return 'Min'
                  if (+value === +meta?.max_price) return 'Max'
                  return IntlService.compactFormat(value)
                }}
                disabled={price.min === price.max}
                marks={price.marks}
                normalize={price.scaleRange}
                format={price.parseRange}
              />
            </Box>
          </FormControl>
          <Box my={{ xs: 2, lg: 0 }} mx={{ xs: 0, lg: 2 }} />
          <Field
            id="by_condition"
            name="by_condition"
            label="Condition"
            component={RadioGroupField}
            items={conditionOptions}
            style={{ marginTop: -6 }}
          />
        </Box>
        <ExtendedButton
          type="submit"
          fullWidth
          className={classes.searchBtnMobile}
        >
          Search
        </ExtendedButton>
      </form>
    </SimpleCard>
  )
}

type StateProps = {
  initialValues: any
}

const getInitialValues = getFormInitialValues('Search_Form')

const makeMapStateToProps = (state): StateProps => {
  return {
    initialValues: {
      ...getInitialValues(state),
      by_condition: conditionOptions[0].value
    }
  }
}

const withConnect = connect(makeMapStateToProps)

const withForm = reduxForm<any, SingletonRouter & StateProps>({
  form: 'Search_Form',
  destroyOnUnmount: true,
  initialValues: InitialValues,
  shouldAsyncValidate: () => false,
  enableReinitialize: true,
  onSubmit: (values, _dispatch, { router, initialValues }) => {
    const searchParams = diff(initialValues, values) as any

    router.push(
      Paths.search.buildSearch({
        ...searchParams,
        location: [searchParams?.location],
        price: searchParams?.price ? values.price : null
      })
    )
  }
})

export default compose<any>(withConnect, withRouter, withForm)(SearchBar)
