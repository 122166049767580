import React from 'react'

import { NextSeo, LogoJsonLd } from 'next-seo'
import { QueryClient } from 'react-query'
import { dehydrate } from 'react-query/hydration'

import AppLayout from '~/components/layouts/AppLayout'
import AdsSlot from '~/components/shared/AdSlot'
import HomepageView from '~/views/Homepage/Homepage.view'

import { getHomePage } from '~/hooks/requests/useHomePage'
import Deserializer from '~/services/Deserializer'
import EmpriseGroup from '~/services/EmpriseGroup'
import Hemax from '~/services/Hemax'
import DFPSlotsProvider from '~/utils/DFPSlotsProvider'

import { formatMessage, getPath } from '~/brands'
import { CACHE_KEYS, SITE_URL } from '~/constants'
import type { NextPageWithLayout } from '~/types/next'

const seoMeta = {
  title: formatMessage('homepage.meta.title'),
  description: formatMessage('homepage.meta.description')
}

const adOptions = {
  pageType: 'homepage',
  pageKey: 'homepage',
  adUnit: 'home'
}

const Home: NextPageWithLayout = () => {
  return (
    <>
      <NextSeo
        title={seoMeta.title}
        titleTemplate="%s"
        description={seoMeta.description}
        openGraph={seoMeta}
      />
      <LogoJsonLd
        logo={`${SITE_URL}/static/images/${getPath('logo.[brand].png')}`}
        url={SITE_URL}
      />
      <DFPSlotsProvider {...adOptions}>
        <AdsSlot
          renderOutOfThePage
          slotId="gam_oop_pos0"
          sizes={[[1, 1]]}
          targetingArguments={{ pos: '0' }}
          disableSizeMaping
        />
        <HomepageView />
      </DFPSlotsProvider>
    </>
  )
}

Home.getLayout = (page: React.ReactElement) => {
  return (
    <AppLayout
      containerProps={{ maxWidth: false, disableGutters: true }}
      disableGutters
    >
      {page}
    </AppLayout>
  )
}

export async function getStaticProps() {
  const queryClient = new QueryClient()

  await queryClient.prefetchQuery(
    CACHE_KEYS.types,
    EmpriseGroup.getMainCategories
  )
  await queryClient.prefetchQuery(CACHE_KEYS.home, async () => {
    const data = await getHomePage()
    return { ...data, featured: [], mostRecent: [] }
  })
  await queryClient.prefetchQuery(CACHE_KEYS.makes, () =>
    EmpriseGroup.getBrands({ with_listings: true }).then((resp) =>
      Deserializer.deserialize(resp)
    )
  )

  await queryClient.prefetchQuery(CACHE_KEYS.severalFeaturedArticles, () =>
    Hemax.getFeaturedArticles({ pageSize: 3 })
  )

  return {
    props: {
      dehydratedState: dehydrate(queryClient)
    },
    revalidate: 60
  }
}

export default Home
