import React, { useCallback, useEffect, useRef, useState } from 'react'

import { WrappedFieldProps } from 'redux-form'

import { TextField, CircularProgress } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import useOptions, { OptionsProps } from '~/hooks/useOptions'

interface Props {
  options: Option[]
  optionSettings?: Partial<OptionsProps>
  label: string
  startAdornment?: object
  handleSelect?: any
  placeholder?: string
  initialValue?: string
  variant: TextFieldProps['variant']
  displayError: string
  helperText?: string
  loading?: boolean
  required?: boolean
  inputChange?: boolean
  clearOnBlur?: boolean
  disabledPopupIcon?: boolean
  disableLoader?: boolean
  disablePortal?: boolean
  loadOptionsOnClick?: boolean
}

const AutocompleteField: React.FC<WrappedFieldProps & Props> = ({
  input: { onChange, onBlur, value, ...input },
  meta: { touched, error },
  label,
  displayError,
  optionSettings = {},
  options: customOptions = [],
  loading = false,
  startAdornment,
  required,
  placeholder,
  clearOnBlur,
  inputChange,
  variant = 'outlined',
  disabledPopupIcon,
  disableLoader,
  disablePortal = true,
  helperText,
  loadOptionsOnClick,
  ...rest
}) => {
  const errorMessage = (touched && error) || displayError
  const [inputValue, setInputValue] = useState('')
  const [trigger, setTrigger] = useState(false)
  const isMount = useRef(false)

  const { options, loading: isLoading, currentValue } = useOptions({
    ...optionSettings,
    key: optionSettings?.key || input.name,
    options: customOptions,
    value,
    enabled: loadOptionsOnClick ? trigger : true
  })

  useEffect(() => {
    // reset value when dependencies change
    if (isMount.current && !currentValue && value) {
      onChange(null)
    }

    isMount.current = true
  }, [currentValue, value])

  const handleInputChange = useCallback(
    (_e, newValue) => {
      if (!isLoading) {
        if (!newValue) onChange(null)

        setInputValue(newValue)
      }
    },
    [onChange, isLoading]
  )

  const handleSelect = useCallback(
    (_e, newValue) => {
      const currentNewValue = newValue?.value || newValue || null

      onChange(currentNewValue)
    },
    [onChange]
  )

  const hasLoading = !disableLoader && (loading || isLoading)

  return (
    <>
      <Autocomplete
        value={currentValue || null}
        loading={loading || isLoading}
        placeholder={placeholder}
        options={options}
        popupIcon={disabledPopupIcon && null}
        getOptionSelected={(option): boolean =>
          option.value === (currentValue.value || currentValue)
        }
        onOpen={() => {
          if (loadOptionsOnClick && !trigger) {
            setTrigger(true)
          }
        }}
        getOptionLabel={(option): string => option.label}
        openOnFocus
        disablePortal={disablePortal}
        loadingText="...Loading"
        ListboxProps={{ style: { maxHeight: '190px' } }}
        renderInput={(params): JSX.Element => (
          <TextField
            {...params}
            variant={variant}
            label={label}
            error={!!errorMessage}
            helperText={errorMessage || helperText}
            InputLabelProps={{ required }}
            InputProps={{
              ...(params.InputProps as any),
              disableUnderline: variant !== 'outlined' || undefined,
              startAdornment,
              endAdornment: (
                <>
                  {hasLoading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            inputProps={{
              ...params.inputProps
            }}
            fullWidth
            placeholder={placeholder}
          />
        )}
        {...input}
        {...rest}
        onBlur={(): void => inputChange && !currentValue && onBlur(inputValue)}
        onInputChange={inputChange && handleInputChange}
        clearOnBlur={clearOnBlur || !inputChange}
        onChange={handleSelect}
      />
    </>
  )
}

export default AutocompleteField
