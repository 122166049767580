type Props = {
  handleSubmit: any
  callbackBeforeSubmit?: () => Promise<any>
  callbackAfterSubmit?: () => Promise<any>
}

/**
 * function is used to prevent Uncaught (in promise)
 * async validation error in redux-form
 *
 * @important works only with html form tag NOT Form component from redux-form
 *
 * @param handleSubmit - redux-form function
 * @returns async errors object
 */
export const createSubmit = ({
  handleSubmit,
  callbackBeforeSubmit,
  callbackAfterSubmit
}: Props) => async (event, ...rest): Promise<any> => {
  try {
    if (event) {
      event.preventDefault()
    }

    if (callbackBeforeSubmit) {
      await callbackBeforeSubmit()
    }

    await handleSubmit(event, ...rest)

    if (callbackAfterSubmit) {
      callbackAfterSubmit()
    }

    return {}
  } catch (errors) {
    console.log(`Error`, errors)
    return errors
  }
}
