import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'

import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'

import { Box, Container } from '@material-ui/core'

import ExtendedButton from '~/components/shared/ExtendedButton'
import Text, { Title } from '~/components/shared/Text'

import { isLogged } from '~/state/modules/user'
import Paths from '~/utils/paths'

import { formatMessage, getEntityByBrand } from '~/brands'

import { useHomepageHeroStyles } from './homages.styles'
import SearchBar from './SearchBar'

import imageBoat from '~/../public/static/images/home-hero.boat.jpg'
import imageRv from '~/../public/static/images/home-hero.rv.jpg'

const title = formatMessage('homepage.title')
const description = formatMessage('homepage.selling')

const heroImage = getEntityByBrand<string>({ boat: imageBoat, rv: imageRv })

const HomepageHero = ({ meta }) => {
  const classes = useHomepageHeroStyles()
  const router = useRouter()
  const logged = useSelector(isLogged)

  const handleClick = () => {
    if (logged) {
      router.push(Paths.sell.index)
    } else {
      router.push(Paths.package.index)
      toast.error('You need to be logged in to create listings.')
    }
  }

  return (
    <Box className={classes.hero} style={{ backgroundColor: '#474d50' }}>
      <Image
        src={heroImage}
        className="home-hero-image"
        alt="hero"
        priority
        placeholder="blur"
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      />
      <Container maxWidth="lg" style={{ zIndex: 1, position: 'relative' }}>
        <Container
          maxWidth="md"
          style={{ minHeight: 255, display: 'flex', alignItems: 'center' }}
        >
          <Title
            mx="auto"
            my={3}
            textAlign="center"
            variant="h1"
            style={{ color: 'white' }}
          >
            {title}
          </Title>
        </Container>
        <SearchBar meta={meta} />

        <Box
          mt={4}
          mx="auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
          color="common.white"
        >
          <Text
            textAlign="center"
            fontSize={20}
            fontFamily="Prompt, sans-serif"
            mb={2}
            color="inherit"
          >
            {description}
          </Text>

          <ExtendedButton
            py="6px"
            variant="contained"
            onClick={handleClick}
            className={classes.createAdBtn}
            bgcolor="#ffffff"
          >
            Create Ad
          </ExtendedButton>
        </Box>
      </Container>
    </Box>
  )
}

export default React.memo(HomepageHero)
