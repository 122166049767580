import React, { FC, useCallback, useState, useEffect } from 'react'

import { WrappedFieldProps } from 'redux-form'

import { Slider } from '@material-ui/core'
import { Mark, SliderProps } from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'

type Styles = {
  lastMark: number
}

const useStyles = makeStyles({
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '6px solid currentColor',
    marginTop: -12,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  track: {
    height: 4,
    borderRadius: 4
  },
  rail: {
    height: 2,
    borderRadius: 4,
    background: '#808080'
  },
  price: (props: Styles) => {
    const lastMark = `& > .MuiSlider-markLabel[data-index="${props.lastMark}"]`
    const firstMark = `& > .MuiSlider-markLabel[data-index="0"]`

    return {
      '& .MuiSlider-valueLabel > span': {
        transform: 'rotate(-45deg) scale(1.2) !important',
        '& > span': {
          fontSize: 8
        }
      },
      '& .MuiSlider-markLabel': {
        transform: 'none !important'
      },
      [lastMark]: {
        right: '0% !important',
        left: 'auto !important',
        marginRight: '-12px'
      },
      [firstMark]: {
        marginLeft: '-12px'
      },
      '& > .MuiSlider-mark': {
        opacity: 0
      }
    }
  }
})

type Props = {
  price?: boolean
  marks?: Mark[]
}

const SliderField: FC<WrappedFieldProps & SliderProps & Props> = ({
  input: { onChange, value, name },
  price,
  defaultValue,
  marks,
  ...rest
}) => {
  const classes = useStyles({ lastMark: marks?.length - 1 || 1 })
  const [inputValue, setInputValue] = useState(value || defaultValue)

  useEffect(() => {
    if (value) setInputValue(value)
  }, [value])

  const handleChange = useCallback(
    (_e: any, newValue: number | number[]) => setInputValue(newValue),
    []
  )

  return (
    <Slider
      onChange={handleChange}
      value={inputValue}
      name={name}
      defaultValue={defaultValue}
      onChangeCommitted={() => onChange(inputValue)}
      marks={marks}
      {...rest}
      classes={{
        root: price && classes.price,
        thumb: classes.thumb,
        track: classes.track,
        rail: classes.rail
      }}
    />
  )
}

export default SliderField
