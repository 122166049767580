// @ts-nocheck
import React, { FC } from 'react'

import { WrappedFieldProps } from 'redux-form'
import striptags from 'striptags'

import { TextField as TextInput } from '@material-ui/core'

export type TextFieldProps = {
  displayError?: boolean
  required?: boolean
  helperText?: string
}

const TextField: FC<WrappedFieldProps & TextFieldProps> = ({
  input,
  meta: { touched, error },
  displayError,
  helperText,
  required,
  InputProps,
  variant = 'outlined',
  ...rest
}) => {
  const errorMessage = (touched && error) || displayError

  return (
    <TextInput
      data-cy="text-field"
      fullWidth
      variant={variant}
      error={!!errorMessage}
      helperText={errorMessage || helperText}
      size="medium"
      InputProps={{
        ...InputProps
      }}
      InputLabelProps={{
        required
      }}
      inputProps={{
        'data-required': required
      }}
      {...input}
      {...rest}
      onBlur={() => {
        const newValue = striptags(input.value).trim()
        input.onBlur(newValue)
      }}
    />
  )
}

export default TextField
