import React, { FC } from 'react'

import {
  FormControl as MuiFormControl,
  FormLabel,
  FormHelperText
} from '@material-ui/core'
import { FormControlProps } from '@material-ui/core/FormControl'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import Text, { TextProps } from './Text'

type StyleProps = {
  width: string | number
  maxWidth: string | number
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      label: {
        marginBottom: 10,
        color: theme.palette.common.black,
        fontSize: '18px',
        lineHeight: 1.7
      },
      formControl: {
        width: (props: StyleProps) => props.width,
        maxWidth: (props: StyleProps) => props.maxWidth
      }
    }),
  { name: 'FormControl' }
)

export interface IFormControl extends FormControlProps {
  label?: string
  errorMessage?: string
  helperText?: string | JSX.Element
  colorHelperText?: TextProps['color']
  width?: string | number
  maxWidth?: string | number
}

const FormControl: FC<IFormControl> = ({
  children,
  label,
  helperText,
  errorMessage,
  colorHelperText = 'inherit',
  error,
  width,
  maxWidth,
  required,
  ...rest
}) => {
  const classes = useStyles({ width, maxWidth })
  const text = errorMessage || helperText
  const isError = !!errorMessage || error

  return (
    <MuiFormControl
      fullWidth
      {...rest}
      required={required}
      className={classes.formControl}
    >
      {!!label && (
        <FormLabel
          focused={false}
          filled
          required={required}
          className={classes.label}
          error={isError}
          style={{ cursor: 'text' }}
        >
          <Text
            display="inline-block"
            // @ts-ignore
            variant="subtitle1"
            color={isError ? 'inherit' : 'textPrimary'}
          >
            {label}
          </Text>
        </FormLabel>
      )}

      {children}

      {!!text && (
        <FormHelperText component="div" error={isError}>
          <Text color={colorHelperText} fontSize="inherit">
            {text}
          </Text>
        </FormHelperText>
      )}
    </MuiFormControl>
  )
}

export default FormControl
