import { useQuery } from 'react-query'

import Deserializer from '~/services/Deserializer'
import EmpriseGroup from '~/services/EmpriseGroup'

import { CACHE_KEYS } from '~/constants'

export const getHomePage = () =>
  EmpriseGroup.getHomepage().then(async (resp) => {
    const { featured: respFeatured, most_recent, top_brands, ...meta } = resp
    const [featured, mostRecent, topBrands] = await Promise.all([
      Deserializer.deserialize(respFeatured),
      Deserializer.deserialize(most_recent),
      Deserializer.deserialize(top_brands)
    ])

    return { featured, mostRecent, topBrands, meta }
  })

const useHomePageQuery = () => {
  const state = useQuery(CACHE_KEYS.home, getHomePage, {
    retry: false,
    retryOnMount: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    refetchInterval: false
  })

  return state
}

export default useHomePageQuery
