import React from 'react'

import { InView } from 'react-intersection-observer'

const InViewport = ({ children, minHeight = 250, ...props }) => (
  <InView triggerOnce delay={100} rootMargin="500px 0px" {...props}>
    {({ inView, ref }) => {
      return (
        <div ref={ref}>
          {inView ? children : <div style={{ minHeight, width: '100%' }} />}
        </div>
      )
    }}
  </InView>
)

export default InViewport
