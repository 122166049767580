import dynamic from 'next/dynamic'
import React from 'react'

import { Box } from '@material-ui/core'
import { BoxProps } from '@material-ui/core/Box'
import { ContainerProps } from '@material-ui/core/Container'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import InViewport from '~/containers/InViewport'

import { Theme } from '~/theme/theme'

import Loader from '../shared/Loader'

import Header from './Header/Header'

const Footer = dynamic(() => import('./Footer/Footer'), {
  ssr: false,
  loading: () => <Loader height={425} />
})

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      page: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      },
      container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minWidth: 0,
        marginBottom: (props: { disableGutters: boolean }) =>
          props.disableGutters ? 0 : theme.spacing(9)
      }
    }),
  { name: 'AppLayout' }
)

interface Props extends BoxProps {
  containerProps?: Omit<ContainerProps, 'children'>
  disableGutters?: boolean
}

const AppLayout: React.FC<Props> = ({
  children,
  containerProps,
  disableGutters,
  ...props
}) => {
  const s = useStyles({ disableGutters })

  return (
    <Box bgcolor="#F9FAFB" {...props} className={s.page}>
      <>
        <Header />
        <Box
          id="main"
          component="main"
          {...containerProps}
          className={s.container}
          maxWidth="xl"
        >
          {children}
        </Box>
        <InViewport minHeight={425}>
          <Footer />
        </InViewport>
      </>
    </Box>
  )
}

export default AppLayout
