import React from 'react'

import { FormControlLabel, Radio } from '@material-ui/core'
import { RadioProps } from '@material-ui/core/Radio'

type Props = {
  label: string
  value: string | number | boolean
  disabled?: boolean
}

const RadioField: React.FC<Props & RadioProps> = ({
  value,
  label,
  disabled,
  ...rest
}) => {
  return (
    <FormControlLabel
      disabled={disabled}
      label={label}
      value={value}
      control={<Radio color="primary" disabled={disabled} {...rest} />}
    />
  )
}

export default RadioField
