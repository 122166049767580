export const lengthIntervals = [
  { value: 1, step: 5 },
  { value: 30, step: 10 },
  { value: 100, step: 100 },
  { value: 500, step: 100 }
]

export const lengthIntervalsRV = [
  { value: 1, step: 1 },
  { value: 10, step: 5 },
  { value: 50, step: 5 }
]

export const draftIntervalsBoat = [
  { value: 1, step: 1 },
  { value: 10, step: 2 },
  { value: 20, step: 5 },
  { value: 30, step: 5 }
]

export const towWeightIntervals = [
  { value: 1, step: 5 },
  { value: 30, step: 10 },
  { value: 100, step: 25 },
  { value: 250, step: 50 },
  { value: 500, step: 100 },
  { value: 1000, step: 500 },
  { value: 3000, step: 1000 }
]

export const atmIntervals = [
  { value: 1, step: 5 },
  { value: 30, step: 10 },
  { value: 100, step: 25 },
  { value: 250, step: 50 },
  { value: 500, step: 100 },
  { value: 1000, step: 500 },
  { value: 3000, step: 1000 }
]

export const odometerIntervals = [
  { value: 10000, step: 10000 },
  { value: 100000, step: 50000 },
  { value: 500000, step: 100000 },
  { value: 1000000, step: 250000 }
]

export const priceIntervals = [
  { value: 1000, step: 5000 },
  { value: 30000, step: 10000 },
  { value: 100000, step: 25000 },
  { value: 250000, step: 50000 },
  { value: 500000, step: 100000 },
  { value: 1000000, step: 500000 },
  { value: 3000000, step: 1000000 }
]
